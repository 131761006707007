<template>
  <div class="page">
    <div class="cloud">
      <img src="@/assets/images/cloud.png" />
    </div>
    <div class="birds">
      <img src="@/assets/images/birds.png" />
    </div>

    <div class="main-content">
      <div class="header">
        <div class="logo">
          <img src="@/assets/images/logo.png" />
        </div>
        <div class="text">上海镜台国际教育</div>
      </div>
      <div class="block-1" @click="toNextPage('/message-all')">
        <div class="background-img">
          <img src="@/assets/images/hexagon.svg" />
        </div>
        <div class="text">
          <div>所有</div>
          <div>留言</div>
        </div>
      </div>
      <div class="block-2" @click="toNextPage('/more')">
        <div class="background-img">
          <img src="@/assets/images/hexagon.svg" />
        </div>
        <div class="text">
          <div>了解</div>
          <div>Mirror</div>
        </div>
      </div>
      <div class="block-3" @click="toNextPage('/barrage-wall')">
        <div class="background-img">
          <img src="@/assets/images/hexagon.svg" />
        </div>
        <div class="text">留言墙</div>
      </div>
      <div class="block-4" @click="toNextPage('/message-my')">
        <div class="background-img">
          <img src="@/assets/images/hexagon.svg" />
        </div>
        <div class="text">
          <div>我的</div>
          <div>留言</div>
        </div>
      </div>
      <div class="block-5" @click="toNextPage('/dry-goods')">
        <div class="background-img">
          <img src="@/assets/images/hexagon.svg" />
        </div>
        <div class="text">
          <div>申请</div>
          <div>干货</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    toNextPage(url){
      this.$router.push(url)
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  position: relative;
  background-color: #127987;
  .cloud {
    width: 100%;
    height: 246px;
    position: absolute;
    top: 50%;
    z-index: 1;
    margin-top: -123px;
    animation: 14s linear 0s infinite normal both running
      tempKeyframesForMotion0;
    img {
      height: 100%;
    }
  }
  .birds {
    width: 87px;
    height: 44px;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 25px;
    margin-top: 88px;
    animation: 8s linear 0s infinite normal both running tempKeyframesForMotion1;
    img {
      height: 100%;
    }
  }

  .main-content {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    width: 320px;
    height: 486px;
    transform: translate(-50%, -50%);
    .header {
      position: absolute;
      z-index: 15;
      width: 116.548px;
      top: 43.715px;
      left: 101.559px;
      transform: rotateZ(0deg);
      display: block;
      .logo {
        height: 44.8397px;
        text-align: center;
        animation: 2s ease 0.5s 1 normal both running fadeIn;
        img {
          height: 100%;
        }
      }
      .text {
        text-align: center;
        font-family: Tahoma,Helvetica,Arial;
        font-size: 14px;
        color: rgb(252, 243, 203);
        animation: 2s ease 1s 1 normal both running fadeIn;
      }
    }
    .block-1 {
      .background-img {
        position: absolute;
        z-index: 5;
        width: 75.7276px;
        height: 65.5335px;
        top: 147.543px;
        left: 53.0969px;
        animation: 2s ease 0.6s 1 normal both running rollInRight;
        img {
          transform: rotateZ(24deg);
          height: 100%;
          width: 100%;
        }
      }
      .text {
        position: absolute;
        z-index: 6;
        width: 76px;
        height: 64px;
        top: 151.067px;
        left: 52px;
        padding: 5px;
        font-family: Tahoma,Helvetica,Arial;
        font-size: 18px;
        color: rgb(7, 121, 137);
        line-height: 1.5;
        text-align: center;
        box-sizing: border-box;
        animation: 1s ease 1.3s 1 normal both running zoomIn;
      }
    }
    .block-2 {
      .background-img {
        position: absolute;
        z-index: 5;
        width: 75.7276px;
        height: 65.5335px;
        top: 153.543px;
        left: 196.097px;
        animation: 2s ease 0.6s 1 normal both running rollInLeft;
        img {
          transform: rotateZ(342deg);
          height: 100%;
          width: 100%;
        }
      }
      .text {
        position: absolute;
        z-index: 6;
        width: 75.7276px;
        height: 65.5335px;
        top: 153.543px;
        left: 196.097px;
        padding: 5px;
        font-family: Tahoma,Helvetica,Arial;
        font-size: 18px;
        color: rgb(7, 121, 137);
        line-height: 1.5;
        text-align: center;
        box-sizing: border-box;
        animation: 1s ease 1.3s 1 normal both running zoomIn;
      }
    }
    .block-3 {
      .background-img {
        position: absolute;
        z-index: 5;
        width: 101px;
        height: 88px;
        top: 205.944px;
        left: 109.29px;
        animation: 2s ease 1.7s 1 normal both running zoomIn;
        img {
          height: 100%;
          transform: rotateZ(342deg);
          width: 100%;
        }
      }
      .text {
        position: absolute;
        z-index: 6;
        width: 101px;
        height: 88px;
        line-height: 88px;
        top: 200.944px;
        left: 109.29px;
        padding: 5px;
        font-family: Tahoma,Helvetica,Arial;
        font-size: 18px;
        color: rgb(7, 121, 137);
        text-align: center;
        box-sizing: border-box;
        font-size: 24px;
        font-weight: bold;
        animation: 1s ease 1.8s 1 normal both running zoomIn;
      }
    }
    .block-4 {
      .background-img {
        position: absolute;
        z-index: 5;
        width: 75.7276px;
        height: 65.5335px;
        top: 281.543px;
        left: 49.0969px;
        animation: 2s ease 0.6s 1 normal both running rollInRight;
        img {
          transform: rotateZ(342deg);
          height: 100%;
          width: 100%;
        }
      }
      .text {
        position: absolute;
        z-index: 6;
        width: 75.7276px;
        height: 65.5335px;
        top: 281.543px;
        left: 49.0969px;
        padding: 5px;
        font-family: Tahoma,Helvetica,Arial;
        font-size: 18px;
        color: rgb(7, 121, 137);
        line-height: 1.5;
        text-align: center;
        box-sizing: border-box;
        animation: 1s ease 1.3s 1 normal both running zoomIn;
      }
    }
    .block-5 {
      .background-img {
        position: absolute;
        z-index: 5;
            width: 75.7276px;
    height: 65.5335px;
    top: 263.543px;
    left: 195.097px;
        animation: 2s ease 0.6s 1 normal both running rollInLeft;
        img {
          transform: rotateZ(342deg);
          height: 100%;
          width: 100%;
        }
      }
      .text {
        position: absolute;
        z-index: 6;
            width: 75.7276px;
    height: 65.5335px;
    top: 263.543px;
    left: 195.097px;
        padding: 5px;
        font-family: Tahoma,Helvetica,Arial;
        font-size: 18px;
        color: rgb(7, 121, 137);
        line-height: 1.5;
        text-align: center;
        box-sizing: border-box;
        animation: 1s ease 1.3s 1 normal both running zoomIn;
      }
    }
  }
}
</style>